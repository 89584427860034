import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import type { AxiosResponse, AxiosError } from 'axios'

import type { FilterValue } from 'components/Table'

import { listRoleCandidates } from 'services/roleCandidates'

import type RoleCandidate from 'redesign/types/RoleCandidate'

import type { IUsePartnerRoleCandidatesReturn } from './usePartnerRoleCandidates.types'

type Params = {
  assignee?: FilterValue | number | null
  profile?: string | number | null
  partnerRole?: number | null
  'partnerRole.partner'?: number | null
  'shortlistedAt_null'?: boolean | null
  'reservedAt_null'?: boolean | null
  'pitchedAt_null'?: boolean | null
  'bookedAt_null'?: boolean | null
  'closedAt_null'?: boolean | null
  _limit?: number | null
  _start?: number | null
  '_where[_and][0][closedAt_null]'?: boolean | null
  '_where[_and][1][bookedAt_null]'?: boolean | null
  _sort?: string | null
  'profile.fullName_contains[]'?: string[] | null
}

type UsePartnerRoleCandidatesProps = {
  enabled?: boolean
  params?: Params
}

const usePartnerRoleCandidates = ({
  enabled,
  params
}: UsePartnerRoleCandidatesProps = {}): IUsePartnerRoleCandidatesReturn => {
  const {
    data: rolesData,
    isLoading,
    refetch,
    error,
    isRefetching
  } = useQuery<AxiosResponse<RoleCandidate[]>, AxiosError>({
    queryKey: ['role-candidates', params],
    enabled: enabled,
    queryFn: () =>
      listRoleCandidates({
        params: {
          populate: [
            'assignee',
            'profile',
            'profile.profileSnooze',
            'profile.country',
            'partnerRole',
            'partnerRole.partner',
            'partnerRole.roleSkills',
            'partnerRole.roleSkills.skill',
            'transferredTo',
            'transferredTo.partnerRole',
            'transferredTo.partnerRole.partner',
            'shortlistedBy',
            'reservedBy',
            'pitchedBy',
            'bookedBy',
            'closedBy',
            'contactedBy',
            'xProfileRequestedBy',
            'presentedToBizdevBy',
            'profile_screenings',
            'profile_screenings.author',
            'profile_tech_evals',
            'profile_tech_evals.author',
            'possibleActions',
            'activeAssignees',
            'inactiveAssignees',
            'clientInterviewedBy',
            'acceptedBy',
          ],
          ...params
        }
      })
  })

  const data = useMemo(() => rolesData?.data || [], [rolesData])
  const totalDataCount = useMemo(() => rolesData?.data?.length || 0, [rolesData])
  const openDataCount = useMemo(() => {
    if (!Array.isArray(rolesData)) {
      return 0
    }
    return rolesData.filter(
      (item: RoleCandidate) => item.closedAt === null && item.bookedAt === null
    ).length
  }, [rolesData])

  return {
    data: data,
    dataCount: totalDataCount,
    openDataCount,
    isLoading: isLoading || isRefetching,
    refetch,
    error
  }
}
export default usePartnerRoleCandidates
