import React, { forwardRef } from 'react'

import type { InputRef } from 'antd'

import { DropdownFilter } from 'components/DropdownFilter'
import { Input } from 'components/Input'

import type { TableColumnSearchFilterProps } from './TableColumnSearchFilter.types'

const TableColumnSearchFilter = forwardRef<
  InputRef,
  TableColumnSearchFilterProps
>(
  (
    { placeholder, confirm, clearFilters, selectedKeys, setSelectedKeys, ...props },
    ref
  ) => {
    const handleSearch = () => {
      confirm()
    }

    const handleReset = () => {
      if (clearFilters) {
        clearFilters()
      }
      setSelectedKeys([])
      confirm()
    }

    return (
      <DropdownFilter {...props} handleFilter={handleSearch} handleReset={handleReset}>
        <Input
          ref={ref}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={e => {
            e.preventDefault()
            e.stopPropagation()
            handleSearch()
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
      </DropdownFilter>
    )
  }
)

export default TableColumnSearchFilter
