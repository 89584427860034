import React from 'react'

import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons'

import { Tooltip } from '../Tooltip'

import { icon, active, inactive } from './EnabledIcon.module.css'

const Link = ({ children, linkTo }) => (
  <a
    data-testid={'linkTo-component'}
    href={linkTo}
    target="_blank"
    rel="noreferrer"
    onClick={event => event.stopPropagation()}
  >
    {children}
  </a>
)

const LinkWrapper = ({ children, linkTo }) => {
  if (linkTo) {
    return <Link linkTo={linkTo}>{children}</Link>
  }

  return children
}

const EnabledIcon = ({
  enabled = true,
  activeTitle = 'Open',
  inactiveTitle = 'Closed',
  linkTo = ''
}) => (
  <div className={icon}>
    {enabled ? (
      <LinkWrapper linkTo={linkTo}>
        <Tooltip title={activeTitle}>
          <CheckCircleFilled className={active} data-testid={'active-icon'} />
        </Tooltip>
      </LinkWrapper>
    ) : (
      <Tooltip title={inactiveTitle}>
        <CloseCircleFilled className={inactive} data-testid={'inactive-icon'} />
      </Tooltip>
    )}
  </div>
)

export default EnabledIcon
