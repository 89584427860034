import type { RecentActivityType } from 'redesign/types/RecentActivity'

export const RECENT_ACTIVITY_TYPES: RecentActivityType = {
  SHORTLISTED: {
    text: 'Shortlisted',
    value: 'SHORTLISTED',
    filter: {
      shortlistedAt_null: false
    }
  },
  RESERVED: {
    text: 'Reserved',
    value: 'RESERVED',
    filter: {
      reservedAt_null: false
    }
  },
  PITCHED: {
    text: 'Pitched',
    value: 'PITCHED',
    filter: {
      pitchedAt_null: false
    }
  },
  BOOKED: {
    text: 'Booked',
    value: 'BOOKED',
    filter: {
      bookedAt_null: false
    }
  },
  CLOSED: {
    text: 'Closed',
    value: 'CLOSED',
    filter: {
      closedAt_null: false
    }
  },
  CONTACTED: {
    text: 'Contacted',
    value: 'CONTACTED',
    filter: {
      contactedAt_null: false
    }
  },
  X_PROFILE: {
    text: 'X-Profile',
    value: 'X_PROFILE',
    filter: {
      xProfileRequestedAt_null: false
    }
  },
  BIZDEV: {
    text: 'BizDev',
    value: 'BIZDEV',
    filter: {
      presentedToBizdevAt_null: false
    }
  },
  SCREENED: {
    text: 'Screened',
    value: 'SCREENED',
    filter: {
      'profile_screenings.roleFit': true
    }
  },
  QUALIFIED: {
    text: 'Qualified',
    value: 'QUALIFIED',
    filter: {
      'profile_tech_evals.roleFit': true
    }
  },
  CLIENT_INTERVIEWED: {
    text: 'Client Interviewed',
    value: 'CLIENT_INTERVIEWED',
    filter: {
      clientInterviewedAt_null: false
    }
  },
  ACCEPTED: {
    text: 'Accepted',
    value: 'ACCEPTED',
    filter: {
      acceptedAt_null: false
    }
  }
}
