import React, { useState } from 'react'

import { RoleCandidateActionsButtonContainer } from '../RoleCandidateActionsButtonContainer'

import type {
  HandleRoleCandidateAssigneeChangeType,
  RoleCandidatesContainerProps
} from 'redesign/containers/RoleCandidatesTableContainer/RoleCandidatesTableContainer.types'

import { RoleCandidatesTable } from './components/RoleCandidatesTable'
import type {
  Pagination,
  RoleCandidateFilters
} from './components/RoleCandidatesTable/RoleCandidatesTable.types'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useOpenXProfile } from 'redesign/hooks/useOpenXProfile'
import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'
import { useRoleCandidates } from 'redesign/hooks/useRoleCandidates'
import { useRoleCandidatesCount } from 'redesign/hooks/useRoleCandidatesCount'
import { useUser } from 'redesign/hooks/useUser'
import { useUserList } from 'redesign/hooks/useUserList'

const RoleCandidatesTableContainer = ({
  filters,
  showActionColumn,
  shouldDisplayRole = false,
  isBizdev
}: RoleCandidatesContainerProps) => {
  const [tableFilters, setTableFilters] = useState<
    RoleCandidateFilters | Record<string, never>
  >({
    _sort: isBizdev ? 'recentActivity:DESC' : null
  })

  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)

  const { data: user } = useUser()
  const { data: usersList } = useUserList()
  const { data: authData } = useUser()
  const { data: featureFlags } = useFeatureFlags()
  const shouldDisplayRoleAssignees = Boolean(
    featureFlags?.ROLE_AND_ROLE_CANDIDATE_ASSIGNEES
  )

  const defaultFilterParams: RoleCandidateFilters = {
    ...filters,
    ...tableFilters
  }

  const { data: roleCandidatesCount } =
    useRoleCandidatesCount(defaultFilterParams)

  const { data: roleCandidates, isLoading } = usePartnerRoleCandidates({
    params: {
      _start: (page - 1) * limit,
      _limit: limit,
      _sort: tableFilters._sort || 'updated_at:DESC',
      ...defaultFilterParams
    }
  })

  const { openXProfile } = useOpenXProfile()

  const {
    updateRoleCandidate,
    isUpdateLoading,
    addRoleAssigneesMutation,
    removeRoleAssigneesMutation
  } = useRoleCandidates()

  const handlePagination = ({ page, pageSize }: Pagination) => {
    setPage(page)
    setLimit(pageSize)
  }

  const onTableChange = (filters: RoleCandidateFilters) => {
    setTableFilters(filters)
  }

  const handleAssigneeChange =
    (roleCandidateId: number) =>
      ({ userId, isAssigned }: HandleRoleCandidateAssigneeChangeType) => {
        const functionToCall = isAssigned
          ? removeRoleAssigneesMutation
          : addRoleAssigneesMutation
        functionToCall({ roleCandidateId, assignees: [userId] })
      }

  return (
    <RoleCandidatesTable
      page={page}
      limit={limit}
      total={roleCandidatesCount}
      handlePagination={handlePagination}
      roleCandidates={roleCandidates}
      isLoading={isLoading || isUpdateLoading}
      user={user}
      usersList={usersList}
      authData={authData}
      onAssign={updateRoleCandidate}
      onTableChange={onTableChange}
      filters={defaultFilterParams}
      openXProfile={openXProfile}
      shouldDisplayRole={shouldDisplayRole}
      shouldDisplayRoleAssignees={shouldDisplayRoleAssignees}
      handleAssigneeChange={handleAssigneeChange}
      ActionsComponent={roleCandidate =>
        showActionColumn ? (
          <RoleCandidateActionsButtonContainer roleCandidate={roleCandidate} />
        ) : null
      }
    />
  )
}

export default RoleCandidatesTableContainer
