export const removeNullValues = <T extends object>(obj: T): Partial<T> => {
  const result: Partial<T> = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== null) {
      result[key] = obj[key]
    }
  }

  return result
}
