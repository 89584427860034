import React from 'react'

import { App, ConfigProvider } from 'redesign/components'
import { appTheme } from 'theme'

import HeaderMenu from '../../../components/LayoutContainer/HeaderMenu'

import { FeatureFlagsContextController } from 'modules/feature-flags/feature-flags-context-controller'

import { useUser } from '../../hooks/useUser'

import type { MenuKey } from 'redesign/types/Menu'

type LayoutProps = {
  children: React.ReactNode
  menuSelectedKeys?: MenuKey[]
  showHeaderMenu?: boolean
}

const Layout = ({ children, menuSelectedKeys, showHeaderMenu = true }: LayoutProps) => {
  const { data: authData, signOut } = useUser()

  return (
    <ConfigProvider theme={appTheme}>
      <App>
        <FeatureFlagsContextController shouldGetAuthToken={false}>
          {showHeaderMenu && <HeaderMenu
            authData={authData}
            onSignOut={signOut}
            menuSelectedKeys={menuSelectedKeys}
          />}
          {children}
        </FeatureFlagsContextController>
      </App>
    </ConfigProvider>
  )
}

export default Layout
