import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import type { RoleCandidateFilters } from 'redesign/containers/RoleCandidatesTableContainer/RoleCandidatesTableContainer.types'

import { countRoleCandidates } from 'services/roleCandidates'

import type { useRoleCandidatesCountReturn } from './useRoleCandidatesCount.types'

const useRoleCandidatesCount = (params: RoleCandidateFilters): useRoleCandidatesCountReturn => {
  const { data: roleCandidatesCount } = useQuery({
    queryKey: ['roleCandidatesCount', params],
    queryFn: () => countRoleCandidates({ params })
  })
  const data: number = useMemo(() => roleCandidatesCount?.data, [roleCandidatesCount])

  return {
    data
  }
}
export default useRoleCandidatesCount
