import React from 'react'

import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons'

import { CavalryUsersMenu } from '../CavalryUsersMenu'

import { Dropdown } from 'components/Dropdown'

import { App } from 'redesign/components/App'

import { formatEmptyText } from 'redesign/utils/stringUtils'

import type User from 'redesign/types/User'

import type { RoleCandidateAssigneeColumnProps } from './RoleCandidateAssigneeColumn.types'

import {
  cellStyles,
  dropdownStyles,
  iconStyles
} from './RoleCandidateAssigneeColumn.module.css'

const RoleCandidateAssigneeColumn = ({
  roleCandidate,
  cavalryUsers,
  isManager,
  user,
  onAssign: onAssignCallback
}: RoleCandidateAssigneeColumnProps) => {
  const { modal: { confirm } } = App.useApp()

  const onAssign = ({ newAssignee }: { newAssignee: User }) => {
    const { username } = newAssignee
    const {
      id,
      profile: { fullName }
    } = roleCandidate
    confirm({
      title: `Assign "${username}" to role candidate: ${fullName}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'YES',
      cancelText: 'CANCEL',
      onOk: () => {
        onAssignCallback({
          roleCandidateId: id,
          values: {
            ...roleCandidate,
            assignee: newAssignee
          }
        })
      }
    })
  }

  return (
    <div className={cellStyles} onClick={e => e.stopPropagation()}>
      <span>{formatEmptyText(roleCandidate?.assignee?.username)}</span>
      {isManager && (
        <span className={dropdownStyles}>
          <Dropdown
            overlay={
              <CavalryUsersMenu
                user={user}
                roleCandidate={roleCandidate}
                cavalryUsers={cavalryUsers}
                onAssign={onAssign}
              />
            }
            trigger={['click']}
            overlayStyle={{
              maxHeight: `${8 * 32 + 42}px`, // 8 items of 32px + the search field of 42px
              minWidth: '250px',
              overflowY: 'auto'
            }}
          >
            <UserOutlined className={iconStyles} title="Change assignee" />
          </Dropdown>
        </span>
      )}
    </div>
  )
}

export default RoleCandidateAssigneeColumn
