import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listPartnerRoles = async params => {
  try {
    const response = await api.get('/partner-roles', params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const readPartnerRole = async (partnerRoleId, params) => {
  try {
    const response = await api.get(`/partner-roles/${partnerRoleId}`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const createPartnerRole = async partnerRole => {
  try {
    const response = await api.post(`/partner-roles`, partnerRole)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const updatePartnerRole = async (partnerRoleId, values) => {
  try {
    const response = await api.put(`/partner-roles/${partnerRoleId}`, values)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const countPartnerRoles = async params => {
  try {
    const response = await api.get('/partner-roles/count', params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const listPartnerRoleComments = async params => {
  try {
    const response = await api.get(`/partner-role-comments`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const createPartnerRoleComment = async params => {
  try {
    const response = await api.post(`/partner-role-comments`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const likePartnerRoleComment = async commentId => {
  try {
    const response = await api.post(`/partner-role-comments/${commentId}/like`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const dislikePartnerRoleComment = async commentId => {
  try {
    const response = await api.post(
      `/partner-role-comments/${commentId}/dislike`
    )
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const deletePartnerRoleComment = async partnerRoleCommentId => {
  try {
    const response = await api.delete(
      `/partner-role-comments/${partnerRoleCommentId}`
    )
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const toggleWatchPartnerRoleComments = async partnerRoleId => {
  try {
    const response = await api.post(
      `/partner-roles/${partnerRoleId}/toggle-watch-comments`
    )
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const listPartnerRolesConfig = async () => {
  try {
    const response = await api.get('/partner-roles/config')
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const fetchSuggestedRoles = async params => {
  try {
    const response = await api.get('/partner-roles/suggestions', params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const countPartnerRolesPerStage = async () => {
  try {
    const response = await api.get('/partner-roles/count-per-stage')
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const summonSourcer = async partnerRoleId => {
  try {
    const response = await api.post(
      `/partner-roles/${partnerRoleId}/summon-sourcer`
    )
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const addAssigneesService = async ({ roleId, assignees }) => {
  try {
    const response = await api.post(
      `/partner-roles/${roleId}/add-assignees`,
      { userIds: assignees },
    )
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const removeAssigneesService = async ({ roleId, assignees }) => {
  try {
    const response = await api.post(
      `/partner-roles/${roleId}/remove-assignees`,
      { userIds: assignees },
    )
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
