import React, { useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'

import { Input } from 'components/Input'
import { Menu } from 'components/Menu'

import { sortByField } from 'utils/helpers'

import type User from 'redesign/types/User'

import type { CavalryUsersMenuProps } from './CavalryUsersMenu.types'

const CavalryUsersMenu = ({
  roleCandidate,
  cavalryUsers,
  onAssign,
  user
}: CavalryUsersMenuProps) => {
  const [search, setSearch] = useState('')

  const isAssigned = (id: number) => id === roleCandidate.assignee?.id

  const unassignedUsers: User[] = cavalryUsers
    ? cavalryUsers.filter(user => !isAssigned(user.id))
    : []

  const handleMenuClick = ({ key }: { key: string }) => {
    const newAssignee = cavalryUsers?.find(item => item.id === Number(key))

    if (newAssignee) {
      onAssign({
        newAssignee
      })
    }
  }

  const unassignedUsersSearchOptions = unassignedUsers
    .filter(
      cavalryUser =>
        cavalryUser.id !== user.id && cavalryUser.email.search(search) >= 0
    )
    .sort(sortByField('email'))
    .map(cavalryUser => ({ label: cavalryUser.email, key: cavalryUser.id }))

  const menuItems = [
    {
      label: (
        <div onClick={e => e.stopPropagation()}>
          <Input
            allowClear
            placeholder="Search"
            prefix={<SearchOutlined />}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            value={search}
          />
        </div>
      ),
      key: 'search'
    },
    ...unassignedUsersSearchOptions
  ]

  if (!isAssigned(user.id)) {
    menuItems.push({ label: 'Me', key: user.id })
  }

  return <Menu onClick={handleMenuClick} items={menuItems} />
}

export default CavalryUsersMenu
