import React, { useCallback, useRef, useState } from 'react'

import type { SorterResult } from 'antd/es/table/interface'
import { Link } from 'gatsby'
import moment from 'moment'

import { RECENT_ACTIVITY_TYPES } from '../../constants'
import { RoleCandidateAssigneeColumn } from '../RoleCandidateAssigneeColumn'
import { XProfileColumn } from '../XProfileColumn'

import { EnabledIcon } from 'components/EnabledIcon'
import type { InputRef } from 'components/Input'
import { ListFilterDropdown } from 'components/ListFilter'
import Pagination from 'components/Pagination/Pagination'
import { ProfileNameColumn } from 'components/ProfileNameColumn'
import type {
  ColumnsType,
  FilterDropdownProps,
  FilterValue,
  TablePaginationConfig,
  TableProps
} from 'components/Table'
import { Table } from 'components/Table'

import { RecentActivities } from 'redesign/components/RecentActivities/RecentActivities'
import { RoleCandidateAssignees } from 'redesign/components/RoleCandidateAssignees'
import { TableColumnSearchFilter } from 'redesign/components/TableColumnSearchFilter'

import { sortByField } from 'utils/helpers'

import { removeNullValues } from 'redesign/utils/objectUtils'
import { onFilterDropdownVisibleChange } from 'redesign/utils/tableHelpers'

import type RoleCandidate from 'redesign/types/RoleCandidate'

import type {
  RoleCandidateFilters,
  RoleCandidatesTableProps
} from './RoleCandidatesTable.types'

const RoleCandidatesTable = ({
  page,
  limit,
  total,
  user,
  usersList,
  authData,
  isLoading,
  roleCandidates,
  onAssign,
  handlePagination,
  ActionsComponent,
  openXProfile,
  onTableChange: onTableChangeCallback,
  filters,
  shouldDisplayRole,
  shouldDisplayRoleAssignees,
  handleAssigneeChange
}: RoleCandidatesTableProps) => {
  const filterInputRef = useRef<InputRef>(null)

  const [profileNameEmailFilter, setProfileNameEmailFilter] =
    useState<FilterValue | null>([])
  const [recentActivities, setRecentActivities] = useState(
    filters?.recentActivity
  )

  const isBizdev = user.role.name === 'Bizdev'

  const columns: ColumnsType<RoleCandidate> = [
    {
      title: 'Name/Email',
      dataIndex: 'profile',
      render: profile => (
        <ProfileNameColumn
          profile={profile}
          filteredValue={profileNameEmailFilter}
        />
      ),
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <TableColumnSearchFilter
          {...props}
          ref={filterInputRef}
          placeholder="Filter by Name or Email"
          clearFilters={() =>
            onTableChangeCallback(
              getActiveFilters({ filters: { ...filters, profile: null } })
            )
          }
        />
      ),
      filteredValue: profileNameEmailFilter,
      onFilterDropdownVisibleChange: visible =>
        onFilterDropdownVisibleChange({ visible, filterInputRef })
    },
    ...(shouldDisplayRole
      ? [
        {
          title: 'Role',
          dataIndex: 'partnerRole',
          key: 'partnerRole',
          render: (value: any) => (
            <Link to={`/role?id=${value.id}`}>
              {value?.partner?.name}: {value?.name}
            </Link>
          )
        }
      ]
      : []),
    {
      title: 'Role Status',
      dataIndex: 'partnerRole',
      key: 'active',
      render: ({ active }) => <EnabledIcon enabled={active} />,
      sorter: true
    },
    {
      title: 'Last Update',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: value => moment(value).fromNow(),
      sorter: true
    },
    {
      key: 'recentActivity',
      title: 'Recent activity',
      render: record => <RecentActivities roleCandidate={record} />,
      sorter: isBizdev,
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'descend',
      filterMultiple: true,
      filteredValue: recentActivities,
      filterDropdown: (props: FilterDropdownProps) => (
        <ListFilterDropdown
          {...props}
          filterKey="recentActivity"
          filters={Object.values(RECENT_ACTIVITY_TYPES).map(type => type)}
          filterMultiple={true}
          onFilterChange={setRecentActivities}
        />
      )
    },
    ...(shouldDisplayRoleAssignees
      ? [
        {
          title: 'Assignees',
          dataIndex: 'activeAssignees',
          key: 'activeAssignees',
          render: (
            _: RoleCandidate['activeAssignees'],
            roleCandidate: RoleCandidate
          ) => (
            <RoleCandidateAssignees
              roleCandidate={roleCandidate}
              authData={authData}
              handleAssigneeChange={handleAssigneeChange}
            />
          ),
          filterMultiple: true,
          filteredValue: filters?.['_where[_and][3][activeAssignees_in]'],
          filterDropdown: (props: FilterDropdownProps) => (
            <ListFilterDropdown
              {...props}
              filterKey="assignees"
              filters={[
                {
                  text: 'Me',
                  value: authData.id
                }
              ].concat(
                usersList
                  ? usersList
                    .filter(user => user.id !== authData.id)
                    .sort(sortByField('email'))
                    .map(user => ({
                      text: user.email,
                      value: user.id
                    }))
                  : []
              )}
              filterMultiple={true}
            />
          )
        }
      ]
      : []),
    ...(!shouldDisplayRoleAssignees
      ? [
        {
          title: 'Assignee',
          dataIndex: 'assignee',
          key: 'assignee',
          className: 'assigneeColumn',
          render: (_: RoleCandidate['assignee'], record: RoleCandidate) =>
            record ? (
              <RoleCandidateAssigneeColumn
                roleCandidate={record}
                cavalryUsers={usersList}
                isManager={true}
                user={user}
                onAssign={onAssign}
              />
            ) : null,
          filters: [
            {
              text: 'Me',
              value: user.id
            },
            {
              text: 'Nobody',
              value: 0
            }
          ].concat(
            usersList
              ? usersList
                .filter(cavalry => cavalry.id !== user.id)
                .sort(sortByField('email'))
                .map(user => ({
                  text: user.email,
                  value: user.id
                }))
              : []
          ),
          filterMultiple: false
        }
      ]
      : []),
    {
      title: 'X-Profile',
      render: roleCandidate => (
        <XProfileColumn
          roleCandidate={roleCandidate}
          openXProfile={openXProfile}
        />
      )
    },
    {
      title: 'Actions',
      width: 350,
      render: ActionsComponent
    }
  ]

  const getActiveFilters = ({
    filters,
    sort
  }: {
    filters: Partial<RoleCandidateFilters>
    sort?: string | null
  }) => {
    let activeFilters: RoleCandidateFilters = {
      _sort: sort,
      assignee: filters?.assignee ?? null,
      activeAssignees: filters?.activeAssignees ?? null,
      '_where[_or][0][profile.fullName_contains]':
        (filters?.profile as string) ?? null,
      '_where[_or][1][profile.email_contains]':
        (filters?.profile as string) ?? null,
      '_where[_or][2][profile.xteamEmail_contains]':
        (filters?.profile as string) ?? null,
      '_where[_and][3][activeAssignees_in]': filters?.activeAssignees
    }
    filters?.recentActivity?.forEach(activity => {
      const filter = RECENT_ACTIVITY_TYPES[activity].filter
      activeFilters = {
        ...activeFilters,
        ...filter
      }
    })
    const formattedResult = removeNullValues(activeFilters)
    return formattedResult
  }

  const onTableChange: TableProps<RoleCandidate>['onChange'] = useCallback(
    (
      _: TablePaginationConfig,
      filters: RoleCandidateFilters,
      sorter: SorterResult<RoleCandidate> | SorterResult<RoleCandidate>[]
    ) => {
      if (Array.isArray(sorter)) {
        return
      }
      const order = sorter.order === 'ascend' ? 'ASC' : 'DESC'
      const sort = sorter.column
        ? `${sorter.column?.dataIndex || sorter.column.key}:${order}`
        : null
      setProfileNameEmailFilter(filters.profile as FilterValue | null)
      const activeFilters: RoleCandidateFilters = getActiveFilters({
        filters,
        sort
      })
      onTableChangeCallback(activeFilters)
    },
    [onTableChangeCallback]
  )

  return (
    <>
      <Pagination
        pageSize={limit}
        current={page}
        onChange={(page: number, pageSize: number) =>
          handlePagination({ page, pageSize })
        }
        total={total}
      />
      <Table
        loading={isLoading}
        rowKey="id"
        columns={columns}
        dataSource={roleCandidates}
        pagination={false}
        scroll={{ x: true }}
        onChange={onTableChange}
      />
      <Pagination
        pageSize={limit}
        current={page}
        onChange={(page: number, pageSize: number) =>
          handlePagination({ page, pageSize })
        }
        total={total}
      />
    </>
  )
}

export default RoleCandidatesTable
